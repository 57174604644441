import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"

import {Col, Row, List, Typography, Alert, Divider} from 'antd';

const data ={

    dahme: [
        {day: "Montags", trainings: ["Kindertraining U8 (4-7 Jahre): 16:45 Uhr - 17:45 Uhr","Jugend-/Erwachsenentraining: 17:45 Uhr - 19:15 Uhr"]},
        {
            day: "Donnerstags",
            trainings: ["Kindertraining U8 (4-7 Jahre): 17:45 Uhr - 18:45 Uhr",
                "Jugend-/Erwachsenentraining: 19:00 Uhr - 20:30 Uhr"
            ]
        },
        {day: "Freitags", trainings: ["Kindertraining U14 (8-14): 17:45 Uhr - 19:15 Uhr"]}
    ],
    goehl: [{day:"Dienstag", trainings: ["Training: 18:00 Uhr - 19:30 Uhr"]}]
};
const Training = () => (
    <Container defKey="3">
        <SEO title="Training"/>
        <div style={{padding: "1rem",}}>
            <Row>
                <Col xs={24}>
                    <h1>Training</h1>
                </Col>
                <Col>
                    Wir treffen uns mehrmals die Woche zum Trainieren.
                    Komm doch einfach mal zu einem Probetraining vorbei. Du brauchst keine Vorkenntnisse oder andere Qualifikationen.
                    <Typography.Text strong> Nimm einfach ein paar Sportsachen und was zu Trinken mit und los gehts.</Typography.Text>
                </Col>
            </Row>
            <Divider/>
            <div>
                <Row>
                    <Col xs={24}>
                        <h2>Dahme</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12}>
                        <iframe
                            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Waldstra%C3%9Fe%201001%2C%2023747%20Dahme+()&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed"
                            width="100%" height="400" frameBorder="0" marginWidth="0" marginHeight="0"
                            scrolling="no"/>
                    </Col>
                    <Col xs={24} md={12} style={{padding: 20}}>
                        <List
                            header={<h3>Trainingszeiten</h3>}
                            dataSource={data.dahme}
                            renderItem={item => (
                                <List.Item>
                                    <Typography.Text
                                        strong>{item.day}</Typography.Text><br/> {item.trainings.map((i) => {
                                    return <>{i}<br/></>;
                                })}
                                </List.Item>
                            )}/>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col xs={24}>
                        <h2>Göhl</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12}>
                        <iframe
    src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Bahnhofstra%C3%9Fe%208%20G%C3%B6hl+(Bahnhofstra%C3%9Fe%208)&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed"
    width="100%" height="400" frameBorder="0" marginWidth="0" marginHeight="0"
    scrolling="no"/>
                    </Col>
                    <Col xs={24} md={12} style={{padding: 20}}>
                        <List
                            header={<h3>Trainingszeiten</h3>}
                            dataSource={data.goehl}
                            renderItem={item => (
                                <List.Item>
                                    <Typography.Text
                                        strong>{item.day}</Typography.Text><br/> {item.trainings.map((i) => {
                                    return <>{i}<br/></>;
                                })}
                                </List.Item>
                            )}/>
                    </Col>
                </Row>
            </div>
        </div>
    </Container>
)

export default Training
